import {
  createContext,
  ReactElement,
  useMemo,
  useState,
  useEffect,
} from 'react';

export const WebContext = createContext<any>('');

interface WebContextProviderProps {
  children: ReactElement | ReactElement[];
}

export const WebContextProvider = (props: WebContextProviderProps) => {
  const { children } = props;
  const [webContent, setWebContent] = useState<any>('');
  const leadValues = useMemo(
    () => ({
      webContent,
      setWebContent,
    }),
    [webContent]
  );

  return (
    <WebContext.Provider value={leadValues}>{children}</WebContext.Provider>
  );
};
