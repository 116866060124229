export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any = [
    { "text": "General Business", "value": "100" },
    { "text": "Accounting", "value": "101" },
    { "text": "Business Administration", "value": "102" },
    { "text": "Business Analytics", "value": "103" },
    { "text": "Communications", "value": "104" },
    { "text": "Economics", "value": "105" },
    { "text": "Finance", "value": "106" },
    { "text": "Human Resources", "value": "107" },
    { "text": "Management", "value": "108" },
    { "text": "Digital Marketing", "value": "109" },
    { "text": "Project Management", "value": "110" },
    { "text": "Sports Management", "value": "111" },
    { "text": "Healthcare Administration", "value": "112" }
]



