import {
  Toolbar,
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  SelectChangeEvent,
  Link,
  Skeleton,
} from '@mui/material';
import { ListingCards } from '../components/ListingCards';
import MenuDropDownComponent from '../components/Sidebars/MenuDropDownComponent';
import { TotalClickedProgramCard } from '../components/Sidebars/TotalClickedProgramCard';
import Banner from '../components/Banner';
import { TopChoiceCards } from '../components/TopChoiceCards';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { degreeInterestOptions, mPrograms } from '../MockData/mData';
import { fetchListingData, getResponse } from '../Service';
import {
  BODY_CONTENT,
  TOTAL_CLICK_COUNT,
  WEB_CONTENT,
  s3SecondaryLogo,
} from '../common/url';
import { TBannerProps, TListingData } from '../types/common';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SeoHandler from '../components/SeoHandler';
import Faq from '../components/Faq';
import { Footer } from '../components/Footer';
import { WebContext } from '../contextProvider/webContextProvider';
import baseURLConfig from '../config/envConfig';

const toolbarStyle = {
  minHeight: '0',
};

const bannerProps_state: TBannerProps = {
  selectedAreaStudy: { category_name: '', category_id: '' },
  selectedDegreeInterest: { type: '', value: '' },
  selectedMProgram: { type: '', value: '' },
};

const BusinessListing = () => {
  const { webContent, setWebContent } = useContext(WebContext);
  const prevLocationRef = useRef<any>();
  const [isContentLoading, setIsContentLoading] = useState(true);
  const pathName = useLocation();
  // const history = useHistory();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentPath = pathName.pathname;
  const [degreeInterestMenuData, setDegreeInterestMenuData] = useState(
    degreeInterestOptions
  );
  // const [webContent, setWebContent] = useState<any>('');
  const [degreeInterestValue, setDegreeInterestValue] = useState('');
  const [areaStudyData, setAreaStudyData] = useState<any>('');
  const [areaStudyValue, setAreaStudyValue] = useState<any>('');
  const [mProgramMenuData, setMProgramMenuData] = useState<any>('');
  const [mProgramValue, setMProgramValue] = useState<string>('');
  const [totalClickCount, setTotalClickCount] = useState();
  const [listingData, setListingData] = useState<TListingData[]>([]);
  const [requestData, setRequestData] = useState('');
  const [bodyContents, setBodyContents] = useState<any[]>([]);
  const [error, setError] = useState();
  const [bannerProps, setBannerProps] =
    useState<TBannerProps>(bannerProps_state);
  const [loading, setLoading] = useState(true);
  const [isSubmitButtonTiger, setIsSubmitButtonTiger] = useState(false);
  const [searchParamsForListingAPI, setSearchParamsForListingAPI] =
    useState<any>('');
  const leadSectionRef = useRef(null);

  useEffect(() => {
    if (searchParamsForListingAPI) {
      getListingData();
    }
  }, [searchParamsForListingAPI]);

  useEffect(() => {
    if (
      webContent &&
      mProgramMenuData &&
      degreeInterestMenuData &&
      areaStudyData
    ) {
      // fetchResponse();
      // fetchedTotalClicks();
      createApiRequest();
    }
  }, [areaStudyData, degreeInterestMenuData, currentPath]);

  const getListingData = async () => {
    try {
      const res = await fetchListingData(searchParamsForListingAPI);
      if (res?.result?.length) {
        const isMobile = window.innerWidth <= 799;
        if (isMobile && isSubmitButtonTiger) {
          scrollToSection(leadSectionRef, 1500);
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
      setListingData(res.result);
      setRequestData(res.mRequestId);
      if (mProgramValue) {
        // const contentResponse = await getResponse(BODY_CONTENT, mProgramValue);
        // setBodyContents(contentResponse[0].sections);
      }
      setIsContentLoading(false);
    } catch (error: any) {
      setError(error);
      setIsContentLoading(false);
    }
  };

  const generateQueryParamsForNavigation = (queryParams: any, path: string) => {
    const shouldNotNavigate = true;
    // queryParams['shouldNotNavigate']=shouldNotNavigate
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    // if(shouldNotNavigate)
    const navigateToNewPath = `/${path}?${queryParamsObject}`;
    return navigateToNewPath;
  };

  const setBannerText = (
    mProgram: any,
    degreeInterest: any,
    areaStudyId?: any
  ) => {
    const getText = areaStudyData?.find(
      (val: any) =>
        val?.areaStudy == areaStudyId || webContent?.defaultData?.areaStudy
    );

    // const getSelectedMProgram = mProgramMenuData?.find(
    //   (val: any) => val?.value == mProgram
    // );
    const getSelectedMProgram = findProgramById(
      webContent?.defaultData?.programContent,
      areaStudyId || webContent?.defaultData?.areaStudy
    )?.find(
      (val: any) => val?.value == (mProgram || webContent?.defaultData?.mProgram)
    );
    const degreeFilter = degreeInterestMenuData.find(
      (i: any) =>
        i.value == degreeInterest
    );
    setBannerProps((prevState: any) => {
      const nextState = { ...prevState };
      if (getSelectedMProgram?.value) {
        nextState.selectedMProgram = getSelectedMProgram;
      }
      if (degreeFilter?.value) {
        nextState.selectedDegreeInterest = {
          type: degreeFilter.type,
          value: degreeFilter.value,
        };
      }
      if (getText?.category_id) {
        nextState.selectedAreaStudy = {
          type: getText?.category_name,
          value: getText?.category_id,
        };
      }
      return nextState;
    });
  };

  const createApiRequest = async () => {
    const path = currentPath.split('/').filter(segment => segment !== '');
    const queryParams: any = Object.fromEntries(searchParams.entries());

    const { degreeInterest, mProgram, areaStudy } = queryParams;

    const getMProgramDetails = (areaStudy: any, mProgramId: any) => {
      const programContent = webContent?.defaultData?.programContent;

      if (!programContent) return null;
      if (areaStudy) {
        const area = programContent.find(
          (area: any) => area?.areaStudy == areaStudy && area?.enable
        );
        if (area) {
          if (!mProgramId) {
            return area.mProgram?.[0] || null;
          } else {
            return (
              area.mProgram?.find(
                (program: any) => program?.id == mProgramId && program?.enable
              ) || null
            );
          }
        }
      } else if (mProgramId) {
        for (const area of programContent) {
          if (area?.enable) {
            const mProgram = area.mProgram?.find(
              (program: any) => program?.id == mProgramId && program?.enable
            );
            if (mProgram) {
              console.log(mProgram);
              return mProgram;
            }
          }
        }
      }

      return null;
    };

    const getAreaStudyFilter = (area: any) =>
      areaStudyData?.find(
        (data: any) => String(data.category_id) === String(area) && data?.status
      );

    const listingAPIParams: any = {
      areaStudy: areaStudy || webContent?.defaultData?.areaStudy,
      mProgram: mProgram || webContent?.defaultData?.mProgram,
      degreeInterest: degreeInterest || webContent?.defaultData?.degreeInterest,
      gradYear: 2008,
      campusType: 2,
      militaryAffiliation: 1,
      startTimeframe: 1,
      sub1: '',
      sub2: '',
      sub3: '',
      webLandingURL: '',
      webInitiatingURL: 'compareonlinebusinessdegrees.com',
      redirect: 0,
      mPubID: webContent?.defaultData?.pubId,
      mPubCampaignID: webContent?.defaultData?.pubCampaignId,
      adsMax: 100,
      enableFilterByCPC: 1,
      enableFilterByBrand: 1,
      ...Object.fromEntries(
        (webContent?.defaultData?.defaultURL || []).map((param: any) => [
          param.key,
          param.value,
        ])
      ),
      ...queryParams,
    };

    const handleInvalidProgram = () => {
      getWebSiteData();
      setSearchParamsForListingAPI({
        ...listingAPIParams,
        mProgram: webContent?.defaultData?.mProgram,
      });
      navigate('/');
    };

    const handleValidProgram = (areaStudyId?: any, mProgramId?: any) => {
      setBannerText(listingAPIParams.mProgram, degreeInterest);
      setSearchParamsForListingAPI({
        ...listingAPIParams,
        areaStudy: areaStudyId || listingAPIParams?.areaStudy,
        mProgram: mProgramId || listingAPIParams?.mProgram,
      });
    };

    if (degreeInterest && webContent?.defaultData?.template == 2) {
      setDegreeInterestValue(degreeInterest);
    }

    const shouldHandleProgram = mProgram || degreeInterest || areaStudy;
    if (path.length <= 1) {
      if (shouldHandleProgram) {
        const mProgramFilter = getMProgramDetails(areaStudy, mProgram);
        if (
          mProgramFilter &&
          !(
            webContent?.defaultData?.template == 2 &&
            mProgramFilter?.areaStudy !== webContent?.defaultData?.areaStudy
          )
        ) {
          getWebSiteData(
            `&mProgram=${mProgramFilter?.id}&areaStudy=${mProgramFilter?.areaStudy}`
          );
          const getAreaStudyValue = getAreaStudyFilter(
            mProgramFilter?.areaStudy
          );

          handleValidProgram(mProgramFilter?.areaStudy, mProgramFilter?.id);
          const newPath = generateQueryParamsForNavigation(
            queryParams,
            `${getAreaStudyValue?.urlKey}/${mProgramFilter?.label
              ?.toLowerCase()
              .replace(/[\s/]+/g, '-')}`
          );
          navigate(newPath);
          setAreaStudyValue(mProgramFilter?.areaStudy);
          findProgramById(
            webContent?.defaultData?.programContent,
            mProgramFilter?.areaStudy
          );
          setMProgramValue(mProgramFilter?.id);
        } else if (degreeInterest) {
          if(webContent?.defaultData?.template == 2){
            setMProgramValue(webContent?.defaultData?.mProgram);
          }
          setBannerText(
            webContent?.defaultData?.mProgram,
            degreeInterest,
            webContent?.defaultData?.areaStudy
          );
          setSearchParamsForListingAPI({
            ...listingAPIParams,
            mProgram: webContent?.defaultData?.mProgram,
            areaStudy: webContent?.defaultData?.areaStudy,
          });
        } else {
          if(webContent?.defaultData?.template == 2){
            setMProgramValue(webContent?.defaultData?.mProgram);
          }
          handleInvalidProgram();
        }
      } else if (path.length === 1) {
        let areaStudyValue: any = '';
        if (path.length) {
          areaStudyValue = areaStudyData?.find(
            (area: any) => area?.urlKey === path[0] && area?.status
          );
        }
        if (webContent?.defaultData?.template == 1) {
          if (!areaStudyValue) {
            handleInvalidProgram();
          } else if (
            areaStudyValue?.category_id !== webContent?.defaultData?.areaStudy
          ) {
            const program = findProgramById(
              webContent?.defaultData?.programContent,
              areaStudyValue?.category_id
            );
            getWebSiteData(
              `&mProgram=${program[0]?.value}&areaStudy=${areaStudyValue?.category_id}`
            );
            setSearchParamsForListingAPI({
              ...listingAPIParams,
              areaStudy: areaStudyValue?.category_id,
              mProgram: program[0]?.value,
            });
            setMProgramValue(program[0]?.value);
            setAreaStudyValue(areaStudyValue?.category_id);
          } else {
            setMProgramValue(webContent?.defaultData?.mProgram);
            setAreaStudyValue(webContent?.defaultData?.areaStudy);
            setBannerText(listingAPIParams.mProgram, degreeInterest);
            setSearchParamsForListingAPI({
              ...listingAPIParams,
              areaStudy: areaStudyValue?.category_id,
            });
          }
        } else {
          if (
            !areaStudyValue ||
            areaStudyValue?.category_id != webContent?.defaultData?.areaStudy
          ) {
            setMProgramValue(webContent?.defaultData?.mProgram);
            // setAreaStudyValue(webContent?.defaultData?.areaStudy)
            handleInvalidProgram();
          } else {
          setMProgramValue(webContent?.defaultData?.mProgram);
          // setAreaStudyValue(webContent?.defaultData?.areaStudy)
            handleValidProgram();
          }
        }
      } else {
        if (prevLocationRef?.current !== currentPath) {
          getWebSiteData();
        }
        if(webContent?.defaultData?.template == 2){
          setMProgramValue(webContent?.defaultData?.mProgram);
        }
        handleValidProgram();
      }
    } else {
      if (shouldHandleProgram) {
        const mProgramFilter = getMProgramDetails(areaStudy, mProgram);
        if (
          mProgramFilter &&
          !(
            webContent?.defaultData?.template == 2 &&
            mProgramFilter?.areaStudy !== webContent?.defaultData?.areaStudy
          )
        ) {
          getWebSiteData(
            `&mProgram=${mProgramFilter?.id}&areaStudy=${mProgramFilter?.areaStudy}`
          );
          const getAreaStudyValue = getAreaStudyFilter(
            mProgramFilter?.areaStudy
          );
          handleValidProgram(mProgramFilter?.areaStudy, mProgramFilter?.id);
          const newPath = generateQueryParamsForNavigation(
            queryParams,
            `${getAreaStudyValue?.urlKey}/${mProgramFilter?.label
              ?.toLowerCase()
              .replace(/[\s/]+/g, '-')}`
          );
          navigate(newPath);
          setAreaStudyValue(mProgramFilter?.areaStudy);
          findProgramById(
            webContent?.defaultData?.programContent,
            mProgramFilter?.areaStudy
          );
          setMProgramValue(mProgramFilter?.id);
        } else if (degreeInterest) {
          const areaStudyValue = areaStudyData?.find(
            (area: any) => area?.urlKey === path[0] && area?.status
          );
          let programValue;
          if (webContent?.defaultData?.template == 1) {
            programValue = webContent?.defaultData?.programContent
              ?.find(
                (category: any) =>
                  category?.areaStudy === areaStudyValue?.category_id
              )
              ?.mProgram?.find(
                (item: any) =>
                  item.label.toLowerCase().replace(/[\s/]+/g, '-') === path[1] &&
                  item?.enable
              );
          } else {
            programValue = mProgramMenuData?.find(
              (program: any) => program?.urlKey === path[1] && program?.status
            );
          }
          if (programValue && areaStudyValue) {
            if(webContent?.defaultData?.template == 2){
              setMProgramValue(programValue?.value);
            }
            setBannerText(
              programValue?.value,
              degreeInterest,
              areaStudyValue?.category_id
            );
            setSearchParamsForListingAPI({
              ...listingAPIParams,
              mProgram: programValue?.value,
              areaStudy: areaStudyValue?.category_id,
            });
            getWebSiteData(
              `&mProgram=${programValue?.value}&areaStudy=${areaStudyValue?.category_id}`
            );
          } else {
            if(webContent?.defaultData?.template == 2){
              setMProgramValue(webContent?.defaultData?.mProgram);
            }
            setSearchParamsForListingAPI(listingAPIParams);
            navigate('/');
          }
        } else {
          if(webContent?.defaultData?.template == 2){
            setMProgramValue(webContent?.defaultData?.mProgram);
          }
          handleInvalidProgram();
        }
      } else {
        const areaStudyValue = areaStudyData?.find(
          (area: any) => area?.urlKey === path[0] && area?.status
        );
        let programValue;
        if (webContent?.defaultData?.template == 1) {
          programValue = webContent?.defaultData?.programContent
            ?.find(
              (category: any) =>
                category?.areaStudy === areaStudyValue?.category_id
            )
            ?.mProgram?.find(
              (item: any) =>
                item.label.toLowerCase().replace(/[\s/]+/g, '-') === path[1] &&
                item?.enable
            );
        } else {
          programValue = mProgramMenuData?.find(
            (program: any) => program?.urlKey === path[1] && program?.status
          );
        }
        if (programValue && areaStudyValue) {
          setAreaStudyValue(areaStudyValue?.category_id)
        setMProgramValue(programValue?.value);
          setBannerText(
            programValue?.value,
            degreeInterest || degreeInterestValue,
            areaStudyValue?.category_id
          );
          setSearchParamsForListingAPI({
            ...listingAPIParams,
            mProgram: programValue?.value,
            areaStudy: areaStudyValue?.category_id,
          });
          getWebSiteData(
            `&mProgram=${programValue?.value}&areaStudy=${areaStudyValue?.category_id}`
          );
        } else {
          if(webContent?.defaultData?.template == 2){
            setMProgramValue(webContent?.defaultData?.mProgram);
          }
          setSearchParamsForListingAPI(listingAPIParams);
          navigate('/');
        }
      }
    }

    setLoading(false);
  };

  const degreeInterestHandle = (event: SelectChangeEvent) => {
    setDegreeInterestValue(event.target.value as string);
  };

  const mProgramHandle = (event: SelectChangeEvent) => {
    setMProgramValue(event.target.value as string);
  };

  const scrollToSection = (
    ref: React.RefObject<HTMLDivElement>,
    duration: number
  ) => {
    if (ref.current) {
      const targetPosition = ref.current.offsetTop; // Get the target position
      const startPosition = window.pageYOffset; // Get the current position

      const distance = targetPosition - startPosition;
      const startTime = performance.now();

      const easeInOutQuad = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; // Easing function

      const animateScroll = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // Calculate progress

        window.scrollTo(0, startPosition + distance * easeInOutQuad(progress)); // Update scroll position

        if (elapsedTime < duration) {
          requestAnimationFrame(animateScroll); // Continue animation until duration is reached
        }
      };

      requestAnimationFrame(animateScroll);
    }
  };

  const handleSubmitDropdown = async () => {
    setIsSubmitButtonTiger(true);
    const pathArea = areaStudyData.find(
      (item: any) =>
        item.category_id ==
        (webContent?.defaultData?.template == 1
          ? areaStudyValue
          : searchParamsForListingAPI?.areaStudy)
    ) as any;

    const pathProgram = mProgramMenuData.find(
      (val: any) => val.value === mProgramValue
    ) as any;
    const pathDegree = degreeInterestMenuData.find(
      (val: any) => val.value === degreeInterestValue
    ) as any;
    setSearchParamsForListingAPI((preState: any) => {
      const newState = {
        ...preState,
        mProgram: mProgramValue,
        degreeInterest: degreeInterestValue,
      };

      if (webContent?.defaultData?.template == 1) {
        newState.areaStudy = areaStudyValue;
      }

      // Return the new state
      return newState;
    });
    getWebSiteData(
      `&mProgram=${mProgramValue}&areaStudy=${
        webContent?.defaultData?.template == 1
          ? areaStudyValue
          : searchParamsForListingAPI?.areaStudy
      }`
    );
    setBannerProps((prevState: any) => ({
      ...prevState,
      selectedMProgram: pathProgram,
      selectedDegreeInterest: pathDegree,
    }));
    const queryParams: any = Object.fromEntries(searchParams.entries());

    const keysToRemove = ['degreeInterest', 'mProgram', 'areaStudy'];
    // Remove keys and their values from queryParams
    keysToRemove.forEach(key => {
      if (key in queryParams) {
        delete queryParams[key];
      }
    });

    const queryParamsObject = new URLSearchParams(queryParams).toString();
    let newPath = `/${pathArea?.urlKey}/${pathProgram?.urlKey}`;
    if (queryParamsObject !== '') {
      newPath += `?${queryParamsObject}`;
    }

    navigate(newPath);
    // const filterCardMobile = document.querySelector('.filter-card.mobile') as HTMLElement;

    // if (filterCardMobile) {
    //     // Scroll the component to the top
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  };

  const universityLists = listingData?.map(i => ({
    mPartner: i.mPartner,
    mBrandID: i.mBrandID,
    mBrandName: i.mBrandName,
    bodyContent: i.bodyContent,
    headContent: i.headContent,
    mPosition: i.mPosition,
    clickURL: i.clickURL,
    Commission: i.Commission,
    imageURL: i.imageURL,
  }));

  const fetchUniversities =
    universityLists &&
    universityLists?.map((iList, id) => {
      return <ListingCards {...iList} key={id} requestData={requestData} />;
    });

  const fetchTopChoiceUniversity = universityLists?.map((iItem, id) => {
    if (iItem.mPosition === 1) {
      return <ListingCards {...iItem} key={id} />;
    }
  });
  const fetchTopPickColleges = universityLists?.map((topItem, id) => {
    if (topItem.mPosition <= 1) {
      return <TopChoiceCards {...topItem} key={id} requestData={requestData} />;
    }
  });

  const fetchedTotalClicks = async () => {
    const totalClickResponse = await getResponse(TOTAL_CLICK_COUNT);
    setTotalClickCount(totalClickResponse);
  };

  const isH3Section = (id: number) => {
    const h3SectionIds = [9, 10, 11, 12, 14, 15, 16, 17];
    return mProgramValue === '100' && h3SectionIds.includes(id);
  };

  const findProgramById = (data: any, areaStudyId: any) => {
    const category = data
      ?.find((category: any) => category?.areaStudy === areaStudyId)
      .mProgram?.map((item: any) => {
        return {
          text: item?.label,
          value: item?.value,
          status: item?.enable,
          urlKey: item?.label?.toLowerCase()?.replace(/[\s/]+/g, '-'),
          areaStudyId: areaStudyId,
        };
      });
    setMProgramMenuData(category);
    return category;
  };

  const getWebSiteData = async (data?: any) => {
    try {
      const contentResponse = await getResponse(
        `${baseURLConfig.webContent}?websiteDomainName=compareonlinebusinessdegrees${
          data ? data : ''
        }`
      );
      if (contentResponse?.data?.defaultData) {
        setWebContent(contentResponse?.data);
      }
      return contentResponse?.data;
    } catch (error: any) {
      // setError(error);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fetchData = async () => {
      const mProgram = searchParams.get('mProgram');
      const data = await getWebSiteData(
        mProgram ? `&mProgram=${mProgram}` : ''
      );

      if (data?.defaultData) {
        const areaStudy = data?.defaultData?.programContent?.map(
          (item: any) => {
            return {
              category_id: item?.areaStudy,
              category_name: item?.label,
              urlKey: item.label.toLowerCase().replace(/[\s/]+/g, '-'),
              status: item?.enable,
            };
          }
        );
        setAreaStudyData(areaStudy);
        // setAreaStudyValue(data?.defaultData?.areaStudy);
        findProgramById(
          data?.defaultData?.programContent,
          data?.defaultData?.areaStudy
        );
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   console.log('mProgramValue', mProgramValue);
  // }, [mProgramValue]);

  const areaStudyHandle = (event: SelectChangeEvent) => {
    // console.log(event.target.value);
    setAreaStudyValue(event.target.value);
    findProgramById(
      webContent?.defaultData?.programContent,
      event.target.value
    );
    setMProgramValue('');
  };

  // useEffect(()=>{
  //   if(webContent){
  //   createApiRequest();
  //   }
  // },[currentPath])

  const handleRelatedProgram = async (val: any) => {
    const pathArea = areaStudyData.find(
      (item: any) => item.category_id == val?.areaStudyId
    ) as any;
    const pathDegree = degreeInterestMenuData.find(
      (val: any) => val.value === degreeInterestValue
    ) as any;
    setSearchParamsForListingAPI((preState: any) => {
      const newState = {
        ...preState,
        mProgram: val?.value,
        // degreeInterest: degreeInterestValue ,
      };
      if (webContent?.defaultData?.template == 1) {
        newState.areaStudy = val?.areaStudyId;
      }
      return newState;
    });
    getWebSiteData(`&mProgram=${val?.value}&areaStudy=${val?.areaStudyId}`);
    setBannerProps((prevState: any) => ({
      ...prevState,
      selectedMProgram: val,
      selectedDegreeInterest: pathDegree,
    }));
    const queryParams: any = Object.fromEntries(searchParams.entries());
    const keysToRemove = ['degreeInterest', 'mProgram', 'areaStudy'];
    keysToRemove.forEach(key => {
      if (key in queryParams) {
        delete queryParams[key];
      }
    });
    const queryParamsObject = new URLSearchParams(queryParams).toString();
    let newPath = `/${pathArea?.urlKey}/${val?.urlKey}`;
    if (queryParamsObject !== '') {
      newPath += `?${queryParamsObject}`;
    }
    navigate(newPath);
  };

  useEffect(() => {
    prevLocationRef.current = pathName.pathname;
  }, [pathName]);

  return isContentLoading ? (
    <>
  
      <Box>
        <Skeleton
          sx={{ height: 220, marginBottom: 1 }}
          animation="wave"
          variant="rectangular"
        />
        <Box
         sx={{
          height: '26px',
          display: { xs: 'none', sm: 'none', md: 'flex' },
          justifyContent: 'center'
        }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            // height={20}
            sx={{ marginBottom: 1 }}
            width="60%"
          />
        </Box>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={40}
          sx={{ marginBottom: 1 }}
          width="100%"
        />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={7}>
            <Skeleton
              height={320}
              animation="wave"
              variant="rectangular"
              width="100%"
              sx={{ marginBottom: 1,borderRadius: 3 }}
            />
            <Skeleton
              height={320}
              animation="wave"
              variant="rectangular"
              width="100%"
              sx={{ marginBottom: 1,borderRadius: 3 }}
            />
            <Skeleton
              height={320}
              animation="wave"
              variant="rectangular"
              width="100%"
              sx={{ marginBottom: 1,borderRadius: 3 }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton
              height={220}
              animation="wave"
              variant="rectangular"
              width="100%"
              sx={{ marginBottom: 3,borderRadius: 3 }}
            />

            <Skeleton
              height={380}
              animation="wave"
              variant="rectangular"
              width="100%"
              sx={{borderRadius: 3 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Skeleton
              sx={{ height: 320 }}
              animation="wave"
              variant="rectangular"
              width="100%"
            />
          </Grid>
        </Grid>
      
      </Box>
    </>
  ) : (
    <>
      <Box>
        <SeoHandler />
        {/* <Toolbar style={toolbarStyle} /> */}
        <Banner bannerProps={bannerProps} />
        <Container sx={{ py: '10px' }} className="mw-1170">
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="custom-loader"></div>
            </div>
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={8} className="result-card">
                
                <Grid item xs={12} className="filter-card mobile">
                  <Box sx={{ width: '100%' }}>
                    <MenuDropDownComponent
                      degreeInterestDDMenu={degreeInterestMenuData}
                      degreeInterestValue={degreeInterestValue}
                      degreeInterestHandler={degreeInterestHandle}
                      areaStudyDDMenu={areaStudyData}
                      areaStudyDDValue={areaStudyValue}
                      mProgramDDMenu={mProgramMenuData}
                      mProgramDDValue={mProgramValue}
                      mProgramDDHandler={mProgramHandle}
                      handleSubmit={handleSubmitDropdown}
                      areaStudyDDHandler={areaStudyHandle}
                    />
                    {/* <TotalClickedProgramCard clickCount={totalClickCount} /> */}
                  </Box>
                </Grid>
                <section ref={leadSectionRef}>{fetchUniversities}</section>
                <Box>
                
                  <div
                    dangerouslySetInnerHTML={{
                      __html: webContent?.contentSettingData?.contentEditor,
                    }}
                  />
                  {webContent?.defaultData?.onlineSchoolsMatching && (
                    <>
                      <Typography
                        component="h2"
                        fontWeight="800"
                        fontSize="35px"
                        mb={3}
                        mt={6}
                      >
                        {' Online Schools Matching Your Search'}
                      </Typography>
                      {fetchTopPickColleges}
                    </>
                  )}
             
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className="filter-card desktop">
                <Box sx={{ width: '100%' }}>
                  <MenuDropDownComponent
                    degreeInterestDDMenu={degreeInterestMenuData}
                    degreeInterestValue={degreeInterestValue}
                    degreeInterestHandler={degreeInterestHandle}
                    areaStudyDDMenu={areaStudyData}
                    areaStudyDDValue={areaStudyValue}
                    mProgramDDMenu={mProgramMenuData}
                    mProgramDDValue={mProgramValue}
                    mProgramDDHandler={mProgramHandle}
                    handleSubmit={handleSubmitDropdown}
                    areaStudyDDHandler={areaStudyHandle}
                  />
                  {mProgramMenuData[0]?.areaStudyId != 9 && (
                    <CardContent sx={{ p: '0px !important' }}>
                      <Typography
                        component="h2"
                        fontWeight="600"
                        sx={{fontSize:'1.5rem'}}
                        // textAlign="center"
                      >
                        Related Programs
                      </Typography>
                      <Box>
                        <ul className="most-list">
                          {mProgramMenuData?.map(
                            (program: any) =>
                              program?.value != mProgramValue &&
                              program?.status && (
                                <li
                                  className="most-item"
                                  key={program?.value}
                                  onClick={() => {
                                    handleRelatedProgram(program);
                                  }}
                                >
                                  {program?.text}
                                </li>
                              )
                          )}
                        </ul>
                      </Box>
                    </CardContent>
                  )}
                  {/* <TotalClickedProgramCard clickCount={totalClickCount} /> */}
                  {webContent?.defaultData?.requestInformation && (
                    <Card className="editorial-reviews">
                      <CardContent sx={{ p: '0px !important' }}>
                        <Typography
                          component="h2"
                          fontWeight="600"
                          textAlign="center"
                        >
                          Request Information
                        </Typography>
                        <Box>
                          {universityLists?.map((iReviews, key) => {
                            const handleVisitSiteClick = () => {
                              const conversionValue = iReviews.Commission;
                              const transactionID = `${requestData}-${iReviews.mPosition}`;

                              window.dataLayer.push({
                                event: 'mm-cod-gtag',
                                conversionValue: conversionValue,
                                transactionID: transactionID,
                              });
                            };

                            return (
                              <div key={key}>
                                <Box p={2} className="list-box">
                                  <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    gap="10px"
                                    sx={{ textDecoration: 'none' }}
                                    component={Link}
                                    href={iReviews.clickURL}
                                    target="_blank"
                                    onClick={handleVisitSiteClick}
                                  >
                                    <img
                                      src={`${s3SecondaryLogo}${iReviews.mBrandID}.webp`}
                                      alt=""
                                    />
                                    <Box flexGrow={1}>
                                      <Typography
                                        component="h3"
                                        fontSize="18px"
                                        color="initial"
                                      >
                                        {iReviews.mBrandName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </div>
                            );
                          })}
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <Faq />
      <Footer />
    </>
  );
};

export default BusinessListing;
